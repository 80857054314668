(function($){

  	sliderLoader();
 	initialLoad();

 	$('.collapse').on('shown.bs.collapse', function () {
        $(this).parent().addClass('active');
    });

    $('.collapse').on('hidden.bs.collapse', function () {
        $(this).parent().removeClass('active');
    });


 	if(!$('body').hasClass('is_mobile')){
	 	$( "#main-menu .menu" ).menu();
	}else{
		$(document).on('click tap','.sub-ctrl',function(){

				$(this).next('.sub-menu').slideDown();
			
		});
	}
  	
  	if($('.sidebar').length){
    	stick();
  	}

  	if($('.matchContent').length){
	  $('.matchContent').matchHeight({
	    byRow : true
	  });
	}
	if($('.matchContentOther').length){
	  $('.matchContentOther').matchHeight({
	    byRow : true
	  });
	}

	if($('.lazy').length){
		$('.lazy').Lazy({ chainable: false });
	}

	//HOME BANNERS
  	if($('#home-banner .bg').length){

		var heroImages = [],
			heroTitle = [],
			heroLocation = [],
			heroLink = [];
	    $('#home-banner .bg img').each(function(){
	    	heroImages.push({'src' : $(this).attr('data-src')});
	    	heroTitle.push($(this).attr('data-title'));
	    	heroLocation.push($(this).attr('data-location'));
	    	heroLink.push($(this).attr('data-link'));
	    });

		var slidesCount = heroImages.length;
          
		$('#home-banner .bg').vegas({
	        slides: heroImages,
	        timer: true,
	      	delay: 15000,
	      	transition : ['fade'],
	      	animation: '',
	      	overlay: true,
	      	autoplay: true,
	      	walk: function (index, slideSettings) {
		        $('#home-banner .bg-ctrls .item').removeClass('active');
		    	$('#home-banner .bg-ctrls .item:eq( '+ index +' )').addClass('active');

		    	$('#home-banner .property-title').text(heroTitle[index]);
		    	$('#home-banner .property-location').text(heroLocation[index]);
		    	$('#home-banner .property-link').attr("href",heroLink[index]);
		    }
	    });
	}

	
})(jQuery);