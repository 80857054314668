(function($){


	//Newsletter
	if ($.cookie('whenToShowDialog') == null) {
        // Create expiring cookie, 2 days from now:
        $.cookie('whenToShowDialog', 'yes', { expires: 2, path: '/' });
        $('#modalNewsletter').modal('show');
    
    }
	
	$('#form-loan').on('click','.btn-submit',function(){

		$('#form-loan').validate({
	  		focusInvalid: false,
	        rules: {
	            price: {
	                required: true
	            },
	            dp: {
	                required: true,
	            },
	            currency:{
	            	required: true,
	            },
	            years:{
	            	required: true,
	            }
	        },
	         messages :{
		        price : {
		            required : 'Please Enter Estimated Price'
		        },
		        dp : {
		            required : 'Please Select Down Payment'
		        },
		        currency:{
	            	required: 'Please Select Currency',
	            },
		        years:{
	            	required: 'Please Select Years to Pay',
	            }
		    },
		    submitHandler: function() {

		    	$price = parseFloat($('#price').val().replace(/,/g, ''));
		    	$dp = parseFloat($('#dp').val());
		    	$years = parseFloat($('#years').val());
		    	$dp_price = parseFloat($price * $dp);
		    	$dp_price_display = parseFloat($price * $dp).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		    	$loan_amount = parseFloat($price-$dp_price);
		    	$loan_amount_display = parseFloat($price-$dp_price).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		    	$amortization = parseFloat(($price-$dp_price)/$years);
		    	$amortization_display = parseFloat(($price-$dp_price)/$years).toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");


		    	$('#dp_price').val($dp_price_display);
		    	$('#loan_amount').val($loan_amount_display);
		    	$('#monthly').val($amortization_display);
		    }
	    });
	});


	$(document).on('click','.btn-career',function(){
		var $title = $(this).data('title'); 
		$('#modalCareer .position input').val($title);
		$('#modalCareer').modal('show');

		//$(this)position
		return false;
	});

	$('.input-wrapper .screen-reader-text').html('No files chosen');
	$('.input-wrapper').on('change','input',function(e){

		$(this).closest('.input-wrapper').find('.screen-reader-text').html(e.target.files[0].name);
		
	});


	$('#header .bot').sticky();
	/*
	* Home Banner Controls
	*/
	$('#home-banner .bg-ctrls').on('click','.item',function(){
		 $('#home-banner .bg').vegas('jump', $(this).index());
	});
	/*
	* Mobile Menu
	*/
  	$(document).on('click tap','.mobile-menu-ctrl',function(){

	    if($('.mobile-menu-ctrl').hasClass('active')){
			$('.mobile-menu-ctrl').removeClass('active');
			$('body').removeClass('mobile-open').removeClass('overlay-open');
	    }else{
			$('.mobile-menu-ctrl').addClass('active');
			$('body').addClass('mobile-open').addClass('overlay-open');
	    }

	    return false;
 	});

  	//$('.mobile-menu-list .menu-item-has-children > a').append('<span class="sub-ctrl"><i class="fas fa-caret-right"></i></span>');
  	$('.mobile-menu-list').on('click tap','.sub-ctrl',function(){

	    if($(this).closest('li').hasClass('active')){
			$(this).closest('li').removeClass('active');
			$(this).next('.sub-menu').slideUp();
	    }else{
			$(this).closest('li').addClass('active');
			$(this).next('.sub-menu').slideDown();
	    }
	    return false;
  	});

	/*
	* Modal
	*/
	$(document).on('click tap','.btn-modal',function(){

    	$('#modalDefault').modal('show');

    	return false;
  	});
 	$(document).on('click tap','.btn-list',function(){

    	$('#modalList').modal('show');

    	return false;
  	});
	/*
	* Scroll to Specific Div
	*/
  	$(document).on('click tap','.scroll-to-div',function(){
	    var divSelected = $(this).data('div');
	    $('html, body').animate({
	        scrollTop: $("#"+divSelected).offset().top - $('#header').outerHeight()
	    }, 2000);
	    return false;
  	});


	/*
	* Back to Top
	*/
	$(window).on('scroll',function () {
		if ($(this).scrollTop() > 100) {
			$('#back-top').addClass('active');
		} else {
			$('#back-top').removeClass('active');
		}

		if ($(this).scrollTop() > $('#header .top').outerHeight()) {
			$('#header').addClass('scrolled');
		}else{
			$('#header').removeClass('scrolled');
		}
	});
	$('#back-top').on('click tap',function () {
		$('#back-top').addClass('fired');
		$('body,html').animate({
		    scrollTop: 0
		}, 800, function() {  
		  $('#back-top').removeClass('fired');
		  $('#back-top').removeClass('active');
		});
		return false;
	});


	/*
	* Window Load
	*/
	$(window).on("load", function(){

    	/* Site Loader */
	    if($('.cssload-wrapper').length){
	       $('.cssload-wrapper').fadeOut();
	    }
	    $(window).resize();
	});

  	/*
	* Window Resize
	*/
	$(window).resize(function() {

    	/* Sticky Sidebar */
    	if($('.blog-sidebar').length){
  			$('.blog-sidebar').sticky('update');

	      if (Modernizr.mq('(min-width: 992px)')) {
	        stick();
	      }else{
	        unstick();
	      }
	    }

	    /* Match Height JQuery */
    	jQuery.fn.matchHeight._update();
 	});



 	$(window).on('orientationchange', function() {
    
 	 	/* Match Height JQuery */
    	jQuery.fn.matchHeight._update();

    	/* Sticky Sidebar */
    	if($('.blog-sidebar').length){
			
			$('.blog-sidebar').sticky('update');

	      	if (Modernizr.mq('(min-width: 992px)')) {
	        	stick();
	      	}else{
	        	unstick();
	      	}
	    }
 	});


})(jQuery);