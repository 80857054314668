function stick(){

  
  
    var topHeight = $('#header .bot').outerHeight() + 20; 
    $contentareapadding = parseInt($('.content-area').css('padding-bottom'),10);
      
    var botHeight = $('#footer').outerHeight() + $contentareapadding;

    $windowwrapper = $(window).height() - topHeight;
    $sidebarwrapper = $('.blog-sidebar-wrapper').outerHeight();

   // if(!$('.blog-sidebar-wrapper .sticky-wrapper').length && $windowwrapper > $sidebarwrapper){   
      
      $(".blog-sidebar").sticky({
        topSpacing: topHeight,
        bottomSpacing: botHeight
      });

    //}


}

function unstick() {
  $('.sidebar').unstick();
}


function detectSafari(){
  var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return is_safari;
}

function detectIE() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
       // Edge (IE 12+) => return version number
       return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}

function initialLoad(){
  
  (function($){

    $('.lazy').Lazy({
      // your configuration goes here
      scrollDirection: 'vertical',
      effect: 'fadeIn',
      effect: "fadeIn",
      effectTime: 500,
      threshold: 0,
      chainable: true,
      visibleOnly: true,
      onError: function(element) {
          console.log('error loading ' + element.data('src'));
      }
    });

  })(jQuery);
}

function sliderLoader(){

  (function($){


    $('.slick-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: auto,
          autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          swipe: false,
          fade: transition,
          cssEase: 'linear',
        });

      }
    });


    $('.testimonials-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: true,
          infinite: true,
          autoplay: true,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          responsive: [
            {
              breakpoint: 1601,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });

    $('.awards-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: true,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          responsive: [
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });

    

    $('.members-list-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: true,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          responsive: [
            {
              breakpoint: 1601,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });

    $('.property-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: true,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          responsive: [
            {
              breakpoint: 1601,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });



    $('.blog-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: true,
          autoplay: true,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          responsive: [
            {
              breakpoint: 1601,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });


    $('.township-gallery-full').each(function(){
      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: false,
          autoplay: false,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          swipe: false,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          //asNavFor: '.township-gallery-thumbnails'
        });
      }
    });

    $('.grid-gallery-slider').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: false,
          autoplay: false,
        //  autoplaySpeed: speed,
          speed: 800,
          arrows: true,
          swipe: true,
          slidesPerRow: 3,
          rows:2,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        });

      }
    });

    $(".grid-gallery-slider .slick-track").lightGallery({
      selector: '.item'
    }); 

    $('.nav-pills a').on('shown.bs.tab', function(event){
    
      $('.grid-gallery-slider').slick('setPosition');   
      $(".grid-gallery-slider .slick-track").lightGallery({
        selector: '.item'
      });  
    }); 

    $('.township-gallery-thumbnails').on('click','.item',function(){
      $('.township-gallery-thumbnails .item').removeClass('active');

      $('.township-gallery-full').slick('slickGoTo',$(this).index());

      $(this).addClass('active');
    });

    $('.township-gallery-full').on('afterChange', function(event, slick, currentSlide, nextSlide){
        $('.township-gallery-thumbnails .item').removeClass('active');
        $('.township-gallery-thumbnails .item:eq('+currentSlide+')').addClass('active');

        console.log(currentSlide);
    });

    $(".township-gallery-full .slick-track").lightGallery(); 

    $('.product-gallery-full').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: false,
          autoplay: false,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          swipe: false,
          //fade: transition,
          cssEase: 'linear',
        //  asNavFor: '.product-gallery-thumbnails'
        });

      }
    });
    $('.product-gallery-thumbnails').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $('.product-gallery-thumbnails').slick({
          dots: false,
          infinite: true,
          autoplay: false,
          asNavFor: '.product-gallery-full',
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: false,
          swipe: true,
          //fade: transition,
          cssEase: 'linear',
          focusOnSelect: true,
          responsive: [
            {
              breakpoint: 1367,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 769,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });
    

    $(".product-gallery-full  .slick-track").lightGallery(); 


    $('.gallery-post').each(function(){

      if( $(this).length && !$(this).hasClass('slick-initialized')){

        var speed = $(this).data('speed');
        var auto = $(this).data('auto') ? true : false;
        var transition = $(this).data('transition') ? true : false;

        $(this).slick({
          dots: false,
          infinite: false,
          autoplay: false,
        //  autoplaySpeed: speed,
          speed: 800,
          slidesToShow: 4,
          slidesToScroll: 1,
          arrows: true,
          swipe: false,
          //fade: transition,
          cssEase: 'linear',
          prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
          nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
          responsive: [
            {
              breakpoint: 1367,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 1201,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
          ]
        });

      }
    });

     $(".lightgallery .slick-track").lightGallery(); 
  })(jQuery);

}


